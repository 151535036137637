import axios from "./axios";
import Qs from 'qs'

const header = {
    'content-type': 'application/x-www-form-urlencoded',
}

export const queryAlarmAll = (param) => {
    return axios.request({
        method: 'get',
        headers: header,
        url: '/queryalarmall/?' + Qs.stringify(param),
    })
}

export const queryAlarmCCAll = (param) => {
    return axios.request({
        method: 'get',
        headers: header,
        url: '/queryalarmccall/?' + Qs.stringify(param),
    })
}

export const loginLogic = (param) => {
    return axios.request({
        url: '/login/',
        method: 'post',
        headers: header,
        data: Qs.stringify(param),
    })
}

export const queryGroup = (param) => {
    return axios.request({
        url: '/querygroup/',
        method: 'post',
        headers: header,
        data: Qs.stringify(param),
    })
}

export const handled = (param) => {
    return axios.request({
        url: '/alarm/handled/',
        method: 'post',
        headers: header,
        data: Qs.stringify(param),
    })
}

export const delAlarm = (param) => {
    return axios.request({
        url: '/alarm/delalarm/',
        method: 'post',
        headers: header,
        data: Qs.stringify(param),
    })
}

export const delNewAlarm = (param) => {
    return axios.request({
        method: 'get',
        headers: header,
        url: '/delnewalarm/?' + Qs.stringify(param),
    })
}

export const statisticsAlarm = (param) => {
    return axios.request({
        url: '/statisticsalarm/',
        method: 'post',
        headers: header,
        data: Qs.stringify(param),
    })
}


export const getDeviceManagement = (param) => {
    return axios.request({
        url: '/devicemanagement/',
        method: 'post',
        headers: header,
        data: Qs.stringify(param),
    })
}

export const queryProjects = (param) => {
    return axios.request({
        url: 'https://www.ipu-cloud.com/otherapi/getNodeList?pid=2299527347&factory=' + param.factory,
        method: 'get',
        headers: header,
    })
}